import React, { useEffect, Fragment, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import { useCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { orderSchoolMedia } from "@selectors";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { getGoogleMapsSatelliteViewLink } from "@helpers/getGoogleMapsSateliteViewLink";
import { VideoThumbnail } from "@components/VideoThumbnail";
import { mapMediaCategoryName } from "@components/MediaGallery/mapMediaCategoryName";
import {
  processVideoSource,
  shouldOpenInNewTab,
} from "@components/MediaGallery/processVideoSource";
import { Lightbox } from "@ui-kit/Lightbox";
import { normalizeUrlDomain } from "@utils/normalizeUrlDomain";

const EditPhotos: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [lightBoxItem, setLightBoxItem] = useState<Models.Media | null>(null);
  const css = useCss();

  useEffect(() => {
    const query = qs.parse(search);

    if (!!query.galleryCategory) {
      setTimeout(() => {
        document
          .getElementById(`gallery-category-${query.galleryCategory}`)
          ?.scrollIntoView();
        history.replace({
          search: qs.stringify({
            ...query,
            galleryCategory: undefined,
          }),
        });
      }, 100);
    }
  }, []);

  const renderEmptyView = () => (
    <div>
      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        School photos
      </Typography>
      <Spacer size="small" />
      <Typography bolded align="center" color="textSecondary">
        Coming soon
      </Typography>
      <Spacer size="medium" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        School videos
      </Typography>
      <Spacer size="small" />
      <Typography bolded align="center" color="textSecondary">
        Coming soon
      </Typography>
      <Spacer size="medium" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        Parent testimonials
      </Typography>
      <Spacer size="small" />
      <Typography bolded align="center" color="textSecondary">
        Coming soon
      </Typography>
    </div>
  );

  const renderVideoSection = (
    title: string,
    media: {
      id: Models.MediaCategory;
      items: Models.Media[];
    }[],
  ) => {
    if (!media.some((t) => !!t.items.length)) {
      return null;
    }

    return (
      <>
        <Spacer size="medium" />

        <Typography variant="h4" bolded align="center" css={css.sectionTitle}>
          {title}
        </Typography>

        {media
          .map((c) => {
            if (c.items.length) {
              return (
                <div key={c.id} id={`gallery-category-${c.id}`}>
                  <Typography
                    variant="h5"
                    align="center"
                    paragraph
                    css={css.categoryLabel}
                  >
                    {mapMediaCategoryName[c.id]}
                  </Typography>

                  {c.items.map((m) => (
                    <div css={css.videoWrapper} key={m.id}>
                      <VideoThumbnail
                        file={m.file}
                        onClick={() => {
                          if (
                            !shouldOpenInNewTab(
                              processVideoSource(m.file.original),
                            )
                          ) {
                            setLightBoxItem(m);
                          } else {
                            window.open(
                              normalizeUrlDomain(m.file.original),
                              "_blank",
                            );
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              );
            }
          })
          .filter(Boolean)}
      </>
    );
  };

  const photos = orderSchoolMedia(facility);
  const videos = orderSchoolMedia(facility, "video");
  const testimonials = orderSchoolMedia(facility, "testimonials");
  const orderedMedia = [
    ...photos.map((p) => p.items).flat(1),
    ...videos.map((v) => v.items).flat(1),
    ...testimonials.map((t) => t.items).flat(1),
  ];

  return (
    <Dialog
      open
      onClose={() => {
        history.push({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      data-test="facility-gallery"
      tier={facility.subscriptionTier}
      title="Facility multimedia"
    >
      <div css={css.container}>
        <Typography variant="h4" bolded align="center" css={css.sectionTitle}>
          Photo galleries
        </Typography>
        <Spacer size="small" />

        <Button
          variant="outlined"
          color="secondary"
          href={getGoogleMapsSatelliteViewLink(
            facility.address.location.lon,
            facility.address.location.lat,
          )}
          target="_blank"
          fullWidth
        >
          Satellite view
        </Button>
        <Spacer />
        <Button
          variant="outlined"
          color="secondary"
          href={facility.mediaFacebookPhotosUrl}
          target="_blank"
          disabled={!facility.mediaFacebookPhotosUrl}
          fullWidth
        >
          Facebook page
        </Button>
        <Spacer />
        <Button
          variant="outlined"
          color="secondary"
          href={facility.mediaWebsitePhotosUrl}
          target="_blank"
          disabled={!facility.mediaWebsitePhotosUrl}
          fullWidth
        >
          School website
        </Button>
        <Spacer />
        <Button
          variant="outlined"
          color="secondary"
          href={facility.mediaGooglePhotosUrl}
          target="_blank"
          disabled={!facility.mediaGooglePhotosUrl}
          fullWidth
        >
          Google photos
        </Button>

        <Spacer size="medium" />

        {!facility.media.length ? (
          renderEmptyView()
        ) : (
          <>
            {!!lightBoxItem && (
              <Lightbox
                sources={orderedMedia
                  .map(
                    (m) =>
                      processVideoSource(m.file.original).content as string,
                  )
                  .filter(Boolean)}
                onClose={() => setLightBoxItem(null)}
                slide={
                  orderedMedia
                    .filter(
                      (m) => !!processVideoSource(m.file.original).content,
                    )
                    .findIndex((m) => m.id === lightBoxItem.id) + 1 || 1
                }
              />
            )}

            {!!photos.some((p) => !!p.items.length) && (
              <>
                <Typography
                  variant="h4"
                  bolded
                  align="center"
                  css={css.sectionTitle}
                >
                  School photos
                </Typography>
                <Spacer size="small" />

                {photos
                  .map((c) => {
                    if (c.items.length) {
                      return (
                        <div key={c.id} id={`gallery-category-${c.id}`}>
                          {c.id !== "profile" && (
                            <Typography
                              variant="h5"
                              align="center"
                              paragraph
                              css={css.categoryLabel}
                            >
                              {mapMediaCategoryName[c.id]}
                            </Typography>
                          )}

                          {c.items.map((m) => (
                            <div
                              css={[
                                css.photoWrapper,
                                {
                                  backgroundImage: `url(${m.file.original})`,
                                },
                              ]}
                              onClick={() => setLightBoxItem(m)}
                              data-test={`${m.category}-photo`}
                              data-id={m.category}
                              key={m.id || "new"}
                            />
                          ))}
                        </div>
                      );
                    }
                  })
                  .filter(Boolean)}
              </>
            )}

            {renderVideoSection("School videos", videos)}
            {renderVideoSection("Parent testimonials", testimonials)}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default EditPhotos;
